import {combineReducers} from 'redux';
import auth from './auth';
import trips from './trips';
import bookings from './bookings';
import customerIds from './customerIds';
import vehicle_type_ids from './vehicleTypesId';
import parent_vehicle_type_ids from './parentVehicleTypeIds';
import cities from './cities';
import cityIds from './cityIds';
import modal from './modal';
import vehicles from './vehicles';
import vehicleIds from './vehicleIds';
import vehicleTypes from './vehicleTypes';
import vehicleManagers from './vehicle_managers';

import specialProjectsType from './specialProjectType';

import vendors from './vendors';
import vendorIds from './vendorIds';
import kams from './kams';
import kamIds from './kamIds';
import clusters from './clusters';
import clusterIds from './clusterIds';
import engagedBy from './engagedBy';
import engagedByIds from './engagedByIds';
import customers from './customers';
import drivers from './drivers';
import pages from './pages';
import questions from './questions';
import questionIds from './questionIds';
import haltReasons from './haltReasons';
import delayReasons from './delayReasons';
import driverStatuses from './driverStatuses';
import vehicleChangeReasons from './vehicleChangeReasons';
import terminationReasons from './terminationReasons';
import tripStatus from './tripStatus';
import bookingStatus from './bookingStatus';
import search from './search';
import routes from './routes';
import consignors from './consignors';
import consignorIds from './consignorIds';
import lorryReceipts from './lorryReceipts';
import digitalForms from './digitalForms';
import lorryReceiptIds from './lorryReceiptIds';
import tracking from './tracking';
import vendorFilterData from './vendorFilterData';
import rejectionReasons from './rejectionReasons';
import records from './record';
import states from './states';
import parentCityIds from './parentCityIds';
import Pod from './pod';
import tripDocumentTypes from './tripDocumentTypes';
import tripSupportingDocuments from './tripSupportingDocuments';
import relationship from './relationship';
import zast_driver_guarantor from './zast_driver_guarantor';
import is_transin_user from './is_transin_user';
import vendorAdvanceTypes from './vendorAdvanceTypes';
import vendorBillingTypes from './vendorBillingTypes';
import maintenanceChoices from './maintenanceChoices';
import zastVehicles from './zastVehicles';
import teamTypes from './teamTypes';
import customerCountryCodes from './customerCountryCodes';
import allActiveInactiveVehicles from './allActiveInactiveVehicles';
import tripCharges from './tripCharges';
import advanceRecords from './advanceRecords';
import diesel_records_detail from './advanceDiesel';
import paymentMapping from './pages/finance/index';
import financeaddNew from '../Components/Finance/AddNewAdvance/reducer';
import kamreducer from './cost';
import receivables from './receivables';
import happaycard from './HappayCard';

import costdispute from './costDispute';
import pendingTripsReducer from './pages/finance/pendingTripsReducer';
import advanceProcessReducer from './pages/finance/advanceProcessReducer';
import revenueTrips from './revenueTrips';
import annexures from './annexures';
import costAnnexures from './costAnnexures';
import vendorCost from './vendorCost';
import sendToTally from './sendToTally';
import dhanuka from './dhanuka';
import supplyPriceRange from './SupplyPriceRange';

const rootReducer = combineReducers({
  allActiveInactiveVehicles,
  zastVehicles,
  maintenanceChoices,
  customerCountryCodes,
  teamTypes,
  auth,
  trips,
  bookings,
  customers,
  customerIds,
  cities,
  cityIds,
  kams,
  kamIds,
  clusterIds,
  clusters,
  modal,
  vehicles,
  vehicleIds,
  vehicleTypes,
  vehicleManagers,
  specialProjectsType,
  vendors,
  vendorIds,
  engagedBy,
  engagedByIds,
  drivers,
  pages,
  questions,
  questionIds,
  haltReasons,
  delayReasons,
  driverStatuses,
  vehicleChangeReasons,
  terminationReasons,
  search,
  routes,
  consignors,
  consignorIds,
  lorryReceipts,
  digitalForms,
  lorryReceiptIds,
  tripStatus,
  bookingStatus,
  tracking,
  vendorFilterData,
  rejectionReasons,
  records,
  states,
  parentCityIds,
  Pod,
  tripDocumentTypes,
  tripSupportingDocuments,
  vehicle_type_ids,
  parent_vehicle_type_ids,
  relationship,
  zast_driver_guarantor,
  vendorAdvanceTypes,
  vendorBillingTypes,
  tripCharges,
  advanceRecords,
  revenueTrips,
  annexures,
  costAnnexures,
  vendorCost,
  diesel_records_detail,
  sendToTally,
  pendingTrips: pendingTripsReducer,
  paymentMappings: paymentMapping,
  advanceProcessReducer,
  financeaddNew: financeaddNew,
  kamreducer: kamreducer,
  receivables,
  happaycard,
  costdispute,
  is_transin_user,
  dhanuka,
  supplyPriceRange,
});

export default rootReducer;
